<template>
  <public-layout>
    <div class="mx-auto pt-3 content-center lg:px-12 md:w-1/2 xl:w-2/5">
      <div class="flex flex-col px-6 py-8 border border-gray-100 shadow-2xl rounded bg-white lg:px-12">
        <login-form />
      </div>
    </div>
  </public-layout>
</template>
<script>
import LoginForm from '@/views/public/login/LoginForm.vue';

export default {
  name: 'LoginIndex',

  components: {
    PublicLayout: () => import('@/components/layouts/PublicLayout'),
    LoginForm
  }
}
</script>
